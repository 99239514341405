<template>
  <v-card id="card-modal" class="elevation-4">
    <v-card-title class="justify-start py-2 card-modaltitle">
      <div class="pl-4">
        <h3 class="primary--text pl-4">
          {{ $t("components.views.trip.no_offer_assign.modal_create_driver.new_driver") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" class="cont" @click.stop="closeModal()">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-form v-model="valid">
        <v-container fluid>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <v-text-field
                v-model="driverNew.name"
                required
                outlined
                dense
                hide-details
                :label="$t('components.views.trip.no_offer_assign.modal_create_driver.names')"
                :placeholder="$t('components.views.trip.no_offer_assign.modal_create_driver.names')"
                prepend-icon="fa-solid fa-user"
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_driver.names'),
                    0
                  ),
                ]"
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <v-text-field
                v-model="driverNew.lastName"
                required
                hide-details
                dense
                outlined
                :label="$t('components.views.trip.no_offer_assign.modal_create_driver.last_names')"
                :placeholder="
                  $t('components.views.trip.no_offer_assign.modal_create_driver.last_names')
                "
                prepend-icon="fa-solid fa-user-tag"
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_driver.last_names'),
                    0
                  ),
                ]"
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="5" class="pb-0">
              <v-text-field
                v-model="id"
                required
                disabled
                outlined
                dense
                hide-details
                :label="
                  $t(
                    'components.views.trip.no_offer_assign.modal_create_driver.identification_number'
                  )
                "
                :placeholder="
                  $t(
                    'components.views.trip.no_offer_assign.modal_create_driver.identification_number'
                  )
                "
                :rules="[
                  required(
                    $t(
                      'components.views.trip.no_offer_assign.modal_create_driver.identification_number'
                    ),
                    0
                  ),
                ]"
                prepend-icon="fa-solid fa-id-card"
              />
            </v-col>
            <v-col cols="5" class="pb-0">
              <v-text-field
                v-model="driverNew.telephone"
                v-integer
                required
                hide-details
                dense
                outlined
                :label="$t('components.views.trip.no_offer_assign.modal_create_driver.telephone')"
                :placeholder="
                  $t('components.views.trip.no_offer_assign.modal_create_driver.telephone')
                "
                :rules="[
                  required(
                    $t('components.views.trip.no_offer_assign.modal_create_driver.telephone'),
                    0
                  ),
                ]"
                prepend-icon="fa-solid fa-phone"
              />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="2" class="pb-3 pt-4">
              <v-btn color="secondary" :disabled="active || !valid" @click="newDriver()" depressed>
                <v-icon small class="fa-solid fa-bookmark mr-1" />
                {{ $t("components.views.trip.no_offer_assign.modal_create_driver.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import validate from "@/plugins/validate";

export default {
  props: {
    idDriver: {
      type: String,
      default: "",
    },
    idCompany: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      ...validate,
      active: false,
      driverNew: {
        lastName: "",
        name: "",
        telephone: "",
        id: "",
      },
    };
  },
  computed: {
    id() {
      return this.idDriver;
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    resetFields() {
      this.driverNew.name = "";
      this.driverNew.lastName = "";
      this.driverNew.telephone = "";
      this.driverNew.id = "";
    },

    closeModal() {
      this.resetFields();
      this.$emit("closeForm");
    },

    async newDriver() {
      this.active = true;

      this.driverNew.id = this.id;

      const user = JSON.parse(store.getters["auth/user"]);

      let driver = {
        name: this.driverNew.name,
        lastName: this.driverNew.lastName,
        identification: this.id,
        phone: this.driverNew.telephone,
        idCity: 1602,
        certification: true,
        advisable: true,
        idCompany: this.idCompany
      };

      await this.axios
        .post("/Drivers/Create/", driver)
        .then(() => {
          this.showSnack({
            text: this.$t(
              "components.views.trip.no_offer_assign.modal_create_driver.driver_created_success"
            ),
            title: this.$t("components.views.trip.no_offer_assign.modal_create_driver.success"),
            name: "success",
          });

          //Envio al Edit la cédula creada
          this.$emit("driverCreated", this.driverNew.id);

          this.closeModal();
        })
        .catch((error) => {
          this.showSnack({
            text: error.response.data,
            title: this.$t(
              "components.views.trip.no_offer_assign.modal_create_driver.driver_created_error"
            ),
            name: "error",
          });
        });
      //Reset al Botón de Guardar
      this.active = false;
    },
  },
};
</script>

<style scoped>
#card-modal {
  border: 3px solid #0b0045 !important;
}

.v-divider {
  border-width: 1px !important;
}

#card-modal p {
  font-size: 1.17em !important;
}

.cont {
  position: absolute;
  right: 10px;
}
</style>
