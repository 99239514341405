var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"elevation-4",attrs:{"id":"card-modal"}},[_c('v-card-title',{staticClass:"justify-start py-2 card-modaltitle"},[_c('div',{staticClass:"pl-4"},[_c('h3',{staticClass:"primary--text pl-4"},[_vm._v(" "+_vm._s(_vm.$t("components.views.trip.no_offer_assign.modal_create_driver.new_driver"))+" ")])]),_c('v-btn',{staticClass:"cont",attrs:{"depressed":"","text":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("fa-solid fa-xmark")])],1)],1),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"required":"","outlined":"","dense":"","hide-details":"","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_driver.names'),"placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_driver.names'),"prepend-icon":"fa-solid fa-user","rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_driver.names'),
                  0
                ),
              ]},model:{value:(_vm.driverNew.name),callback:function ($$v) {_vm.$set(_vm.driverNew, "name", $$v)},expression:"driverNew.name"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"required":"","hide-details":"","dense":"","outlined":"","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_driver.last_names'),"placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_driver.last_names'),"prepend-icon":"fa-solid fa-user-tag","rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_driver.last_names'),
                  0
                ),
              ]},model:{value:(_vm.driverNew.lastName),callback:function ($$v) {_vm.$set(_vm.driverNew, "lastName", $$v)},expression:"driverNew.lastName"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"required":"","disabled":"","outlined":"","dense":"","hide-details":"","label":_vm.$t(
                  'components.views.trip.no_offer_assign.modal_create_driver.identification_number'
                ),"placeholder":_vm.$t(
                  'components.views.trip.no_offer_assign.modal_create_driver.identification_number'
                ),"rules":[
                _vm.required(
                  _vm.$t(
                    'components.views.trip.no_offer_assign.modal_create_driver.identification_number'
                  ),
                  0
                ),
              ],"prepend-icon":"fa-solid fa-id-card"},model:{value:(_vm.id),callback:function ($$v) {_vm.id=$$v},expression:"id"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"5"}},[_c('v-text-field',{directives:[{name:"integer",rawName:"v-integer"}],attrs:{"required":"","hide-details":"","dense":"","outlined":"","label":_vm.$t('components.views.trip.no_offer_assign.modal_create_driver.telephone'),"placeholder":_vm.$t('components.views.trip.no_offer_assign.modal_create_driver.telephone'),"rules":[
                _vm.required(
                  _vm.$t('components.views.trip.no_offer_assign.modal_create_driver.telephone'),
                  0
                ),
              ],"prepend-icon":"fa-solid fa-phone"},model:{value:(_vm.driverNew.telephone),callback:function ($$v) {_vm.$set(_vm.driverNew, "telephone", $$v)},expression:"driverNew.telephone"}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pb-3 pt-4",attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.active || !_vm.valid,"depressed":""},on:{"click":function($event){return _vm.newDriver()}}},[_c('v-icon',{staticClass:"fa-solid fa-bookmark mr-1",attrs:{"small":""}}),_vm._v(" "+_vm._s(_vm.$t("components.views.trip.no_offer_assign.modal_create_driver.save"))+" ")],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }