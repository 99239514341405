<template>
  <v-card id="card-modal" class="py-4 elevation-4">
    <v-card-title class="justify-center">
      <div>
        <h3 class="primary--text">
          {{ $t("components.views.settings.driver_vehicle.modal_create_driver.new_driver") }}
        </h3>
      </div>
      <v-btn depressed text color="primary" class="cont" @click.stop="closeModal()">
        <v-icon class="mr-1">fa-solid fa-xmark</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider class="mt-2 mb-3 mx-10 secondary" />
    <v-card-text>
      <v-form v-model="valid">
        <v-container fluid>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <v-text-field v-model="driverNew.name" required outlined hide-details
                :label="$t('components.views.settings.driver_vehicle.modal_create_driver.names')"
                :placeholder="$t('components.views.settings.driver_vehicle.modal_create_driver.names')"
                prepend-icon="fa-solid fa-user" :rules="[
  required(
    $t('components.views.settings.driver_vehicle.modal_create_driver.names'),
    0
  ),
]" />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="10" class="pb-0">
              <v-text-field v-model="driverNew.lastName" required hide-details outlined
                :label="$t('components.views.settings.driver_vehicle.modal_create_driver.last_names')" :placeholder="
  $t('components.views.settings.driver_vehicle.modal_create_driver.last_names')
" prepend-icon="fa-solid fa-user-tag" :rules="[
  required(
    $t('components.views.settings.driver_vehicle.modal_create_driver.last_names'),
    0
  ),
]" />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="5" class="pb-0">
              <v-text-field v-model="id" required disabled outlined hide-details :label="
  $t(
    'components.views.settings.driver_vehicle.modal_create_driver.identification_number'
  )
" :placeholder="
  $t(
    'components.views.settings.driver_vehicle.modal_create_driver.identification_number'
  )
" :rules="[
  required(
    $t(
      'components.views.settings.driver_vehicle.modal_create_driver.identification_number'
    ),
    0
  ),
]" prepend-icon="fa-solid fa-id-card" />
            </v-col>
            <v-col cols="5" class="pb-0">
              <v-text-field v-model="driverNew.telephone" v-integer required hide-details outlined
                :label="$t('components.views.settings.driver_vehicle.modal_create_driver.telephone')" :placeholder="
  $t('components.views.settings.driver_vehicle.modal_create_driver.telephone')
" :rules="[
  required(
    $t('components.views.settings.driver_vehicle.modal_create_driver.telephone'),
    0
  ),
]" prepend-icon="fa-solid fa-phone" />
            </v-col>
          </v-row>
          <v-row class="justify-center">
            <v-col cols="3" class="pb-0">
              <v-btn color="secondary" :disabled="active || !valid" @click="newDriver()" depressed>
                <v-icon small class="fa-solid fa-bookmark mr-1" />
                {{ $t("components.views.settings.driver_vehicle.modal_create_driver.save") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import store from "@/store";
import validate from "@/plugins/validate";

export default {
  props: {
    idDriver: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      valid: false,
      ...validate,
      active: false,
      driverNew: {
        lastName: "",
        name: "",
        telephone: "",
        id: "",
      },
    };
  },
  computed: {
    id() {
      return this.idDriver;
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),

    resetFields() {
      this.driverNew.name = "";
      this.driverNew.lastName = "";
      this.driverNew.telephone = "";
      this.driverNew.id = "";
    },

    closeModal() {
      this.resetFields();
      this.$emit("closeForm");
    },

    async newDriver() {
      this.active = true;

      this.driverNew.id = this.id;

      const user = JSON.parse(store.getters["auth/user"]);

      let driver = {
        name: this.driverNew.name,
        lastName: this.driverNew.lastName,
        identification: this.id,
        phone: this.driverNew.telephone,
        address1: "",
        address2: "",
        idCity: 1602,
        idDepartment: "",
        idCountry: "",
        isState: 0,
        cellPhone: "",
        enable: true,
        createOn: "",
        idCompany: user.user.idCompany,
        idTypeIdentification: 1,
        email: "",
        postalCode: "",
        licence: "",
        certification: true,
        advisable: true,
        expiration: "",
        fullName: "",
        sexo: "",
      };

      await this.axios
        .post("/Drivers/Create/", driver)
        .then(() => {
          this.showSnack({
            text: this.$t(
              "components.views.settings.driver_vehicle.modal_create_driver.driver_created_success"
            ),
            title: this.$t("components.views.settings.driver_vehicle.modal_create_driver.success"),
            name: "success",
          });
        })
        .catch((error) => {
          this.active = false;
          this.showSnack({
            text: error.response.data,
            title: this.$t(
              "components.views.settings.driver_vehicle.modal_create_driver.driver_created_error"
            ),
            name: "error",
          });
        });

      if (this.active) {
        await this.axios
          .get(`/Drivers/AssignCompanies/${driver.identification}`)
          .then(() => {
            this.showSnack({
              text: this.$t(
                "components.views.settings.driver_vehicle.modal_create_driver.driver_created_success"
              ),
              title: this.$t("components.views.settings.driver_vehicle.modal_create_driver.success"),
              name: "success",
            });

            //Envio al Edit la cédula creada
            this.$emit("driverCreated", this.driverNew.id);

            //Reset al Botón de Guardar
            this.active = false;

            this.closeModal();
          })
          .catch((error) => {
            this.active = false;
            this.showSnack({
              text: error.response.data,
              title: this.$t(
                "components.views.settings.driver_vehicle.modal_create_driver.driver_created_error"
              ),
              name: "error",
            });
          });
      }
    },
  },
};
</script>

<style scoped>
@import './ModalCreateDriverStyle.scss';
</style>
